import isClient from 'utils/Browser/isClient'

function Storage({ storage, getPrefix, prefix }) {
  let inMemoryStorage = {}

  function isStorageSupported() {
    try {
      const testKey = 'key'
      storage.setItem(testKey, testKey)
      storage.removeItem(testKey)

      return true
    } catch (e) {
      return false
    }
  }

  function clear() {
    if (isStorageSupported()) {
      storage.clear()
    } else {
      inMemoryStorage = {}
    }
  }

  function getItem(name) {
    if (isStorageSupported()) {
      const value = storage.getItem(getPrefix(name))

      return value ? JSON.parse(value) : null
    } else {
      if (Object.keys(inMemoryStorage).includes(name)) {
        return inMemoryStorage[name]
      }

      return new Error(`property '${getPrefix(name)}-${name}' not found`)
    }
  }

  function key(index) {
    if (isStorageSupported()) {
      return storage.key(index)
    } else {
      return Object.keys(inMemoryStorage)[index]
    }
  }

  function removeItem(name) {
    if (isStorageSupported()) {
      storage.removeItem(getPrefix(name))
    } else {
      delete inMemoryStorage[name]
    }
  }

  function setItem(name, value) {
    if (isStorageSupported()) {
      const namePrefixed = getPrefix(name)
      storage.setItem(namePrefixed, JSON.stringify(value))
    } else {
      inMemoryStorage[name] = value
      return new Error('Saved on storage fallback')
    }
  }

  function clearWithPrefix() {
    if (isStorageSupported()) {
      const allItemsStorage = Object.assign({}, localStorage)
      const allKeysItemsStorage = Object.keys(allItemsStorage)
      const itemsWithoutKey = allKeysItemsStorage.reduce(
        (acc, curr) =>
          curr.includes(prefix)
            ? acc
            : Object.assign({}, acc, { [curr]: allItemsStorage[curr] }),
        {}
      )

      storage.clear()

      Object.entries(itemsWithoutKey).forEach(([key, value]) =>
        localStorage.setItem(key, value)
      )
    }
  }

  return {
    getItem,
    setItem,
    removeItem,
    clear,
    key,
    getLength: () => storage.length,
    clearWithPrefix,
  }
}

function createStorage({ prefix = 'safe' }) {
  const getPrefix = (name) => `${prefix}-${name}`

  return {
    local: Storage({
      storage: isClient() ? window.localStorage : {},
      getPrefix,
      prefix,
    }),
    session: Storage({
      storage: isClient() ? window.sessionStorage : {},
      getPrefix,
      prefix,
    }),
  }
}

export default createStorage

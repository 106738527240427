import Storage from './Storage'
import { APP_NAME, ENV } from 'gatsby-env-variables'

const key = `storage-${APP_NAME}-${ENV}`

/* Methods
  getItem(item)
  setItem(name, value)
  getLength()
  removeItem(name),
  clearWithPrefix(),
  clear(),
*/
const storage = Storage({ prefix: key })

export const handleStorageError = (storageValue, defaultValue) => {
  if (!storageValue) return defaultValue

  if (storageValue instanceof Error) {
    console.error(storageValue)
    return defaultValue
  }

  return storageValue
}

storage.getItem = (item) => handleStorageError(storage.getItem(item), null)

export default storage

import make from 'utils/make'
import { Helmet } from 'react-helmet'
import isClient from 'utils/Browser/isClient'
import { LoaderProvider } from 'hooks/useLoader'
import { CardsProvider } from 'hooks/useCards'
import { LoansProvider } from 'hooks/useLoans'

function wrapElement({ element }) {
  if (isClient()) {
    make.setup()
  }

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'pt-br',
        }}
        title="RVBR Tour"
        titleTemplate="RVBR Tour"
        meta={[
          {
            name: 'description',
            content:
              'Red Ventures Brasil - Ajudamos as pessoas a tomarem as melhores decisões',
          },
        ]}
        link={[
          {
            rel: 'shortcut icon',
            type: 'image/png',
            href: '/favicon.png',
            sizes: '16x16',
          },
        ]}
      />

      <LoaderProvider>
        <CardsProvider>
          <LoansProvider>{element}</LoansProvider>
        </CardsProvider>
      </LoaderProvider>
    </>
  )
}

export default wrapElement

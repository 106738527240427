import classNames from 'classnames'
import { useEffect } from 'react'

import './Loader.scss'

const Loader = ({ text, isLoading }) => {
  useEffect(() => {
    const body = document.querySelector('body')

    if (isLoading) {
      body.style.overflow = 'hidden'
    } else {
      body.style.removeProperty('overflow')
    }
  }, [isLoading])

  return (
    <section className={classNames('loader', { 'loader--show': isLoading })}>
      {text && <p className="loader__text">{text}</p>}

      <div
        className={classNames('loader__progress-bar', {
          'loader__progress-bar--show': isLoading,
        })}
      />
    </section>
  )
}

export default Loader

import { MAKE_WRITE_KEY, MAKE_SOURCE_KEY } from 'gatsby-env-variables'

const setup = () => {
  const makeTagular = {
    writeKey: MAKE_WRITE_KEY,
    sourceKey: MAKE_SOURCE_KEY,
  }

  return new Promise(function (resolve) {
    document.addEventListener('makeLoaded', () => {
      if (window.__Cohesion?.initialized) {
        console.log('%c @analytics/make - ready', 'color: cornflowerblue;')
        resolve()
      }
    })

    if (!window.__Cohesion?.initialized) {
      const style = document.createElement('style')
      const script = document.createElement('script')

      style.innerText =
        '.preampjs [data-preamp], .fusejs [data-fuse] {opacity: 0 !important}'
      document.head.append(style)

      script.innerText = `!function(co,h,e,s,i,o,n){var d='documentElement';var a='className';h[d][a]+=' preampjs fusejs';
      n.k=e;co._Cohesion=n;co._Preamp={k:s,start:new Date};co._Fuse={k:i};co._Tagular={k:o};
      [e,s,i,o].map(function(x){co[x]=co[x]||function(){(co[x].q=co[x].q||[]).push([].slice.call(arguments))}});
      h.addEventListener('DOMContentLoaded',function(){co.setTimeout(function(){
      var u=h[d][a];h[d][a]=u.replace(/ ?preampjs| ?fusejs/g,'')},3e3);
      co._Preamp.docReady=co._Fuse.docReady=!0});var z=h.createElement('script');
      z.async=1;z.src='https://cdn.cohesionapps.com/cohesion/cohesion-latest.min.js';z.onload=${() => {
        document.dispatchEvent(new Event('makeLoaded'))
      }};h.head.appendChild(z);}
      (window,document,'cohesion','preamp','fuse','tagular',{
          tagular:{writeKey: '${makeTagular.writeKey}', sourceKey: '${
        makeTagular.sourceKey
      }', beamHook:function(event){window.dataLayer=window.dataLayer||[];window.dataLayer.push({"event":event["@type"],"tagularPayload":event})}},
      })`

      document.head.append(script)
    } else {
      resolve()
    }
  })
}

export default setup

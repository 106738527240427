module.exports = {
  home: {
    path: '/',
    component: 'src/pages/Landing/index.js',
    pageName: 'lp',
    title: 'RVBR Tour',
  },
  landingA: {
    path: '/lpa',
    component: 'src/pages/LandingA/index.js',
    pageName: 'lp',
    title: 'RVBR Tour',
  },
  landingB: {
    path: '/lpb',
    component: 'src/pages/LandingB/index.js',
    pageName: 'lp',
    title: 'RVBR Tour',
  },

  register: {
    path: '/registro',
    component: 'src/pages/Register/index.js',
    pageName: 'form-basic-data',
    title: 'RVBR Tour formulário - dados básicos',
  },

  cardSelection: {
    path: '/cartoes',
    component: 'src/pages/Cards/CardSelection/index.js',
    pageName: 'form-cards-subproducts',
    title: 'RVBR Tour formulário - cartões opções',
  },
  cardAddress: {
    path: '/cartoes/endereco',
    component: 'src/pages/Cards/CardAddress/index.js',
    pageName: 'form-cards-address',
    title: 'RVBR Tour formulário - cartões endereço',
  },
  cardResume: {
    path: '/cartoes/resumo',
    component: 'src/pages/Cards/CardResume/index.js',
    pageName: 'form-cards-resume',
    title: 'RVBR Tour formulário - cartões resumo',
  },
  cardConclusion: {
    path: '/cartoes/conclusao',
    component: 'src/pages/Cards/CardConclusion/index.js',
    pageName: 'form-cards-feedback',
    title: 'RVBR Tour formulário - cartões pedido realizado',
  },

  investmentSelection: {
    path: '/investimentos',
    component: 'src/pages/Investments/InvestmentSelection/index.js',
    pageName: 'form-investment-subproducts',
    title: 'RVBR Tour formulário - investimento opções',
  },
  investmentDetail: {
    path: '/investimentos/detalhes',
    component: 'src/pages/Investments/InvestmentDetail/index.js',
    pageName: 'form-investment-details',
    title: 'RVBR Tour formulário - investimento detalhes',
  },

  loanValue: {
    path: '/emprestimos',
    component: 'src/pages/Loans/LoanValue/index.js',
    pageName: 'form-loans-ammount',
    title: 'RVBR Tour formulário - empréstimos valor',
  },
  loanSelection: {
    path: '/emprestimos/selecao',
    component: 'src/pages/Loans/LoanSelection/index.js',
    pageName: 'form-loans-subproducts',
    title: 'RVBR Tour formulário - empréstimos opções',
  },
  loanResume: {
    path: '/emprestimos/resumo',
    component: 'src/pages/Loans/LoanResume/index.js',
    pageName: 'form-loans-resume',
    title: 'RVBR Tour formulário - empréstimos resumo',
  },
  loanConclusion: {
    path: '/emprestimos/conclusao',
    component: 'src/pages/Loans/LoanConclusion/index.js',
    pageName: 'form-loans-feedback',
    title: 'RVBR Tour formulário - empréstimo solicitado',
  },

  openAccount: {
    path: '/abrir-conta',
    component: 'src/pages/OpenAccount/index.js',
    pageName: 'form-download-app',
    title: 'RVBR Tour formulário - baixar app',
  },
}

import React, { createContext, useContext, useEffect, useState } from 'react'
import Loader from 'components/Loader'

const LoaderContext = createContext({})

export const useLoader = () => {
  const context = useContext(LoaderContext)

  if (!context) {
    throw new Error(
      `useLoader hook must be used within a 'LoaderProvider' component`
    )
  }

  return context
}

export const LoaderProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [loaderText, setLoaderText] = useState('')
  const [props, setProps] = useState({})

  useEffect(() => {
    if (isLoading) {
      setProps({ className: 'content-loading' })
    } else {
      setProps({})
    }
  }, [isLoading])

  const setLoader = ({ isLoading, text }) => {
    setIsLoading(isLoading)
    setLoaderText(text)
  }

  return (
    <LoaderContext.Provider
      value={{
        setLoader,
        isLoading,
      }}
    >
      <Loader isLoading={isLoading} text={loaderText} />
      {React.cloneElement(<div>{children}</div>, props)}
    </LoaderContext.Provider>
  )
}

import { createContext, useContext, useState } from 'react'

const LoansContext = createContext({})

export const useLoans = () => {
  const context = useContext(LoansContext)

  if (!context) {
    throw new Error(
      `useLoans hook must be used within a 'LoansProvider' component`
    )
  }

  return context
}

export const LoansProvider = ({ children }) => {
  const [loanValue, setLoanValue] = useState(0)
  const [selectedLoan, setSelectedLoan] = useState({})

  return (
    <LoansContext.Provider
      value={{
        loanValue,
        setLoanValue,
        selectedLoan,
        setSelectedLoan,
      }}
    >
      {children}
    </LoansContext.Provider>
  )
}

import make from './index'
import storage from 'utils/Storage'
import { formIdRegister } from 'utils/make/constants'
import generateUUID from 'utils/generateUUID'
import { BASE_URL } from 'gatsby-env-variables'

export const generateAndGetCorrelationId = () => {
  const storagedCorrelatoinId = storage.session.getItem('correlationId')

  if (!storagedCorrelatoinId) {
    const newCorrelationId = generateUUID()
    storage.session.setItem('correlationId', newCorrelationId)

    return newCorrelationId
  }

  return storagedCorrelatoinId
}

const makeTrackings = {
  pageViewed: ({ pageName, url, referrer, title }) => {
    make.track('redventures.usertracking.v2.PageViewed', {
      pageName,
      webContext: { page: { url, referrer, title } },
    })
  },

  LPHeroElementViewed: (heroElementIndex) => {
    const heroElements = [
      {
        name: 'visualization-hero-product-investment',
        position: 'first',
        text: 'invista-do-seu-jeito',
      },
      {
        name: 'visualization-hero-product-cards',
        position: 'second',
        text: 'cartao-de-credito-sem-anuidade-e-limite-pre-aprovado',
      },
      {
        name: 'visualization-hero-product-loans',
        position: 'third',
        text: 'seu-sonho-se-tornando-realidade',
      },
    ]

    const webElement = {
      elementType: 'carousel',
      htmlId: 'ec62a6ca-56d3-4a35-a225-b4b2f2ae6dc1',
      location: 'hero',
      ...heroElements[heroElementIndex],
    }

    make.track('redventures.usertracking.v3.ElementViewed', { webElement })
  },

  LPHeroCTA: (heroElementIndex, buttonText) => {
    const heroElements = [
      {
        name: 'click-hero-products-box-cpf-cta-investment',
        position: 'first',
      },
      {
        name: 'click-hero-products-box-cpf-cta-cards',
        position: 'second',
      },
      {
        name: 'click-hero-products-box-cpf-cta-loans',
        position: 'third',
      },
    ]

    const webElement = {
      elementType: 'button',
      htmlId: '1fd96c46-cfd3-48eb-9011-9314d632abf6',
      location: 'hero',
      text: buttonText,
      ...heroElements[heroElementIndex],
    }

    make.track('redventures.usertracking.v3.ElementClicked', {
      webElement,
      actionOutcome: 'product-selected',
      outboundUrl: `${BASE_URL}/registro`,
    })
  },

  CardsElementViewed: (cardsElementIndex) => {
    const cardsElements = [
      {
        name: 'visualization-form-cards-subproducts-cartao-rvbr',
        position: 'first',
        text: 'cartao-rvbr',
      },
      {
        name: 'visualization-form-cards-subproducts-cartao-rvbr-cashback',
        position: 'second',
        text: 'cartao-rvbr-cashback',
      },
      {
        name: 'visualization-form-cards-subproducts-cartao-rvbr-pass-black',
        position: 'third',
        text: 'cartao-rvbr-pass-black',
      },
    ]

    const webElement = {
      elementType: 'carousel',
      htmlId: '883ad5bd-78f3-4501-9e41-15b7fc73dcc6',
      location: 'form-cards',
      ...cardsElements[cardsElementIndex],
    }

    make.track('redventures.usertracking.v3.ElementViewed', { webElement })
  },

  InvestmentsElementViewed: (investmentsData) => {
    const webElement = {
      elementType: 'list',
      htmlId: 'f8a8e3a8-e67a-421b-8064-a1092db836d9',
      location: 'form-investment',
      ...investmentsData,
    }

    make.track('redventures.usertracking.v3.ElementViewed', { webElement })
  },

  elementClicked: ({
    location = '',
    position = '',
    elementType = '',
    name = '',
    text = '',
    htmlId = '',
    actionOutcome = '',
    outboundUrl = '',
    useCorrelationId = false,
  }) => {
    const correlationId = useCorrelationId ? generateAndGetCorrelationId() : ''

    make.track('redventures.usertracking.v3.ElementClicked', {
      webElement: {
        location,
        position,
        elementType,
        text,
        name,
        htmlId,
      },
      actionOutcome,
      outboundUrl,
      correlationId,
    })
  },

  formViewed: ({ formName = 'debt_negotiation', formType = 'lead' }) => {
    const correlationId = generateAndGetCorrelationId()

    make.track('redventures.usertracking.v3.FormViewed', {
      correlationId,
      formContext: {
        formId: formIdRegister,
        formName,
        formType,
      },
    })
  },

  formStarted: ({
    formName,
    formId,
    formBrand = 'red-tour',
    stepName,
    stepId,
    stepNumber,
  }) => {
    const correlationId = generateAndGetCorrelationId()

    make.track('redventures.usertracking.v3.FormStarted', {
      correlationId,
      formContext: {
        formId,
        formName,
        formBrand,
      },
      stepContext: {
        stepName,
        stepId,
        stepNumber,
      },
    })
  },

  formContinued: ({
    formName,
    formId,
    formBrand = 'red-tour',
    stepName,
    stepId,
    stepNumber,
    field,
  }) => {
    const correlationId = generateAndGetCorrelationId()

    make.track('redventures.usertracking.v3.FormContinued', {
      correlationId,
      formContext: {
        formId,
        formName,
        formBrand,
      },
      stepContext: {
        stepName,
        stepId,
        stepNumber,
      },
      field,
    })
  },

  formBacked: ({
    formName,
    formId,
    formBrand = 'red-tour',
    stepName,
    stepId,
    stepNumber,
  }) => {
    const correlationId = generateAndGetCorrelationId()

    make.track('redventures.usertracking.v3.FormBacked', {
      correlationId,
      formContext: {
        formId,
        formName,
        formBrand,
      },
      stepContext: {
        stepName,
        stepId,
        stepNumber,
      },
    })
  },

  formSubmitted: ({
    formName,
    formId,
    formBrand = 'red-tour',
    stepName,
    stepId,
    stepNumber,
  }) => {
    const correlationId = generateAndGetCorrelationId()

    make.track('redventures.usertracking.v3.FormSubmitted', {
      correlationId,
      formContext: {
        formId,
        formName,
        formBrand,
      },
      stepContext: {
        stepName,
        stepId,
        stepNumber,
      },
    })
  },

  lead: (leadData) => {
    make.track('rvbr.redplatform.tour.Lead.v1', leadData, {
      includeContext: false,
    })
  },

  card: ({ selected, address }) => {
    const lead = storage.session.getItem('user_data')

    make.track('rvbr.redplatform.tour.Card.v1', { selected, address, lead })
  },

  investment: ({ selected }) => {
    const lead = storage.session.getItem('user_data')

    make.track('rvbr.redplatform.tour.Investment.v1', {
      selected,
      lead,
    })
  },

  loan: ({ selected, category, value }) => {
    const lead = storage.session.getItem('user_data')

    make.track('rvbr.redplatform.tour.Loan.v1', {
      selected,
      category,
      value,
      lead,
    })
  },
}

export default makeTrackings

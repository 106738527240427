exports.components = {
  "component---src-pages-cards-card-address-index-js": () => import("./../../../src/pages/Cards/CardAddress/index.js" /* webpackChunkName: "component---src-pages-cards-card-address-index-js" */),
  "component---src-pages-cards-card-conclusion-index-js": () => import("./../../../src/pages/Cards/CardConclusion/index.js" /* webpackChunkName: "component---src-pages-cards-card-conclusion-index-js" */),
  "component---src-pages-cards-card-resume-index-js": () => import("./../../../src/pages/Cards/CardResume/index.js" /* webpackChunkName: "component---src-pages-cards-card-resume-index-js" */),
  "component---src-pages-cards-card-selection-index-js": () => import("./../../../src/pages/Cards/CardSelection/index.js" /* webpackChunkName: "component---src-pages-cards-card-selection-index-js" */),
  "component---src-pages-investments-investment-detail-index-js": () => import("./../../../src/pages/Investments/InvestmentDetail/index.js" /* webpackChunkName: "component---src-pages-investments-investment-detail-index-js" */),
  "component---src-pages-investments-investment-selection-index-js": () => import("./../../../src/pages/Investments/InvestmentSelection/index.js" /* webpackChunkName: "component---src-pages-investments-investment-selection-index-js" */),
  "component---src-pages-landing-a-index-js": () => import("./../../../src/pages/LandingA/index.js" /* webpackChunkName: "component---src-pages-landing-a-index-js" */),
  "component---src-pages-landing-b-index-js": () => import("./../../../src/pages/LandingB/index.js" /* webpackChunkName: "component---src-pages-landing-b-index-js" */),
  "component---src-pages-landing-index-js": () => import("./../../../src/pages/Landing/index.js" /* webpackChunkName: "component---src-pages-landing-index-js" */),
  "component---src-pages-loans-loan-conclusion-index-js": () => import("./../../../src/pages/Loans/LoanConclusion/index.js" /* webpackChunkName: "component---src-pages-loans-loan-conclusion-index-js" */),
  "component---src-pages-loans-loan-resume-index-js": () => import("./../../../src/pages/Loans/LoanResume/index.js" /* webpackChunkName: "component---src-pages-loans-loan-resume-index-js" */),
  "component---src-pages-loans-loan-selection-index-js": () => import("./../../../src/pages/Loans/LoanSelection/index.js" /* webpackChunkName: "component---src-pages-loans-loan-selection-index-js" */),
  "component---src-pages-loans-loan-value-index-js": () => import("./../../../src/pages/Loans/LoanValue/index.js" /* webpackChunkName: "component---src-pages-loans-loan-value-index-js" */),
  "component---src-pages-open-account-index-js": () => import("./../../../src/pages/OpenAccount/index.js" /* webpackChunkName: "component---src-pages-open-account-index-js" */),
  "component---src-pages-register-index-js": () => import("./../../../src/pages/Register/index.js" /* webpackChunkName: "component---src-pages-register-index-js" */)
}


import setup from './setup'

const track = async (
  event,
  args,
  { renewSession = false, includeContext = true } = {}
) => {
  return setup().then(() => {
    window.tagular(
      'beam',
      {
        '@type': event,
        ...args,
      },
      renewSession,
      includeContext
    )
    console.log('%c @analytics/make - track', 'color: cornflowerblue', {
      event,
      ...args,
    })
  })
}

export default {
  track,
  setup,
}

import Root from './src/Root'
import makeTrackings from './src/utils/make/trackings'
import ROUTES from './src/Routes'

import '@redsales/ds/dist/style.css'
import './src/styles/main.scss'

const getRouteByPathname = (pathname) => {
  const route = Object.values(ROUTES).find((route) => route.path === pathname)
  return route || { pageName: '', title: 'RVBR Tour' }
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  const route = getRouteByPathname(location.pathname)

  document.title = route.title
  makeTrackings.pageViewed({
    pageName: route.pageName,
    url: location.href,
    referrer: prevLocation ? prevLocation.href : null,
  })
}

export const wrapRootElement = Root
